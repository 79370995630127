#login{
    text-align: center;
    margin-top: 5rem;
}

h2{
    margin-bottom: 3rem!important;
}
#login * {
    margin-bottom: 1rem;
}

.form {
    margin-top: 4rem;
}
#login Form {
    background-color: rgb(1,45,59,0.9);
    padding: 2rem;
    width: 80%;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin: auto;
    margin-bottom: 2rem;
}

.center{
    display: flex;
    justify-content: center;
    text-decoration: none;
}

.btn{
    text-align: center;
    margin: auto;
}