.folders{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 2em;
    background-color: rgba(1,45,59,0.9);
    margin: auto!important;
    border-radius: 15px;
    padding-bottom: 50px;
    text-align: center;
    align-items: center;
    margin-bottom: 5rem;
}

.drive{
    margin-bottom: 6rem;
}

.files{
    width: 100%;
    height: 100%;
    color:white;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 2em;
    background-color: rgba(1,45,59,0.9);
    margin: auto!important;
    border-radius: 15px;
    padding-bottom: 50px;
    text-align: center;
    align-items: center;
    margin-bottom: 5rem;
    margin-top: 5rem;
}
#dropdown-basic{
    width: 100%;
    margin: 30% 0;    
    background-color: rgba(0,0,0,0);
    border:none;
}

.folder{
    display: flex;
    align-items: center;
    margin: 0.5em;
    padding-top: 0.2em;
    border-radius: 5px;
    background-color: #f0f0f0;
    cursor: pointer;
}

.flexCenter {
    display: flex;
    justify-content: center;
}
    

body {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    color: white;
  }

#folderName {
font-size: 1.2rem;
} 

.driveTitle {
    font-size: 2rem;
    font-weight: bold;
    width: 100%;
    text-align: center;
    margin-top: 2rem!important;
    margin-bottom: 2rem;
    color: rgba(1,45,59,0.9);
    
}

.section {
    padding-top: 1rem;
    padding-bottom: 1rem;
    color:white
}
li{
    font-size: 1rem;
}

.shareableLink{
    font-size: 1rem;
    margin: 0.5em 0;    
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 3rem;
    color:white
}

.shareableLink  h2{
    font-size: 1.3rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2rem;
    color:white
}

.shareableLink Button{
    margin-top: 1rem;
}

.file_form {
    width: 95%
}

.no-arrow::after {
    display: none!important;
    font-size: small;
    border: none;
}

.no-arrow{
    background-color: rgba(0,0,0,0)!important;
    border:none;
}

.no-arrow:hover{
    background-color: rgba(0,0,0,0)!important;
    border:none;
}

.cardTitle {
    font-size: 1rem!important;
}

.card-body {
    padding:0.5rem!important
}

.rename-input{
    position: relative;
    bottom : 0.25rem;
    font-size: 0.8rem!important
}

.center {
    text-align: center;
    color:black;
}

