li {
    font-size: small;
}

#dropdown-filelist {
   font-size: small;
   padding: 0px 10px;
}




.section {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.btn-secondary {
    max-width: 50%;
    text-align: center  ;
}

.file_form {
    display: flex;
    width: 95%;
    margin: 0 auto;
    justify-content: center;
}


.flexCenter {
    display: flex;
    justify-content: center;
}



.frame{
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 0;
}

.iframeContainer{
    margin: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    width: -webkit-fill-available;
    width: -moz-available;
    width: fill-available;
}

.resetStyle {
    all : unset;
}
