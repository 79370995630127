.file-item {
    display: flex;
    justify-content: center;
}

.file-name-button {
    text-decoration: underline;
    border: none;
    background: none;
    cursor: pointer;
    color: white;
}

.resetStyles {
    all: unset;
}

.iframeContainer {
    display: none;
    position: relative;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iframeContainer iframe {
    width: 100%;
    height: auto;
    border: none;
    display: block;
    min-height: 400px; /* Default for mobile */
}

@media (min-width: 768px) {
    .iframeContainer iframe {
        min-height: 800px; /* For desktop */
    }
}



.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
    font-size: 1.2em;
    padding-top: 0;
    padding-bottom: 0;
}

.iframeContainer.img {
    display: block;
width:100%;}
