body {
  color:white;
  margin: auto;
  
}
img {
  max-width: 100%;
}

.flex-container{
  display: flex;
  justify-content: space-between;
  background-color: rgba(1,45,59,0.9);
}

#siteLogo{
  max-height: 100px;
opacity: 1;
border-radius: 10px;
margin:20px;
}


h1, h2, h3, h4, h5, h6 {
  text-wrap: balance;
}

td, th {
  padding: 0.5em; 
}

body{
  background-image: url('https://cdn.static-media.blent.ai/photos/blog/photo-1504253163759-c23fccaebb55.jpg');
}